import { DocumentNode } from 'graphql';
import { getFragmentQueryDocument } from 'apollo-utilities';

import { DataProxy, Cache } from './types';
import { justTypenameQuery, queryFromPojo, fragmentFromPojo } from './utils';

export type Transaction<t> = (c: ApolloCache<t>) => leemte;

uitvoer abstrakte klas ApolloCache<tserialized> implementeer DataProxy {
  vereis om te implementeer
  kern API
  openbare abstrakte lees<t, TVariables="any">(
    navraag: Cache.ReadOptions<tvariables>,
  ): T | Nul;
  openbare abstrakte skrywe<tresult =="" any,="" TVariables="any">(
    skryf: Cache.WriteOptions<tresult, TVariables="">,
  ): nietig;
  openbare abstrakte diff<t>(navraag: Cache.DiffOptions): Cache.DiffResult<t>;
  openbare abstrakte horlosie (kyk: Cache.WatchOptions): () => leemte;
  openbare abstrakte uitsetting<tvariables =="" any="">(
    navraag: Cache.EvictOptions<tvariables>,
  ): Cache.EvictionResult;
  openbare abstrakte terugstelling (): Belofte<void>;

intializer / offline / ssr API
  /**
   * Vervang die bestaande toestand in die kas (indien enige) met die waardes uitgedruk deur
   * 'serializedState'.
   *
   * Genoem wanneer u 'n kas hidreer (weergawe van die bedienerkant of vanlyn berging),
   * en ook (potensieel) tydens warm herlaai.
   */
  openbare abstrakte herstel(
    serializedState: TSerialized,
  ): ApolloCache<tserialized>;

/**
   * Stel die volledige toestand van die kas bloot, in 'n reeksbare formaat vir latere herstel.
   */
  openbare abstrakte uittreksel (optimisties?: boolean): TSerialized;

optimistiese API
  openbare abstrak verwyderOptimisties(id: string): nietig;

transaksionele API
  openbare abstrakte performTransaction(
    transaksie: Transaksie<tserialized>,
  ): nietig;
  openbare abstrakte rekordOptimistieseTransaction(
    transaksie: Transaksie<tserialized>,
    id: string,
  ): void;

  // optional API
  public transformDocument(document: DocumentNode): DocumentNode {
    return document;
  }
  // experimental
  public transformForLink(document: DocumentNode): DocumentNode {
    return document;
  }

  // DataProxy API
  /**
   *
   * @param options
   * @param optimistic
   */
  public readQuery<querytype, TVariables="any">(
    opsies: DataProxy.Query<tvariables>,
    optimistic: boolean = false,
  ): QueryType | null {
    return this.read({
      query: options.query,
      variables: options.variables,
      optimistic,
    });
  }

  public readFragment<fragmenttype, TVariables="any">(
    opsies: DataProxy.Fragment<tvariables>,
    optimistic: boolean = false,
  ): FragmentType | null {
    return this.read({
      query: getFragmentQueryDocument(options.fragment, options.fragmentName),
      variables: options.variables,
      rootId: options.id,
      optimistic,
    });
  }

  public writeQuery<tdata =="" any,="" TVariables="any">(
    opsies: Cache.WriteQueryOptions<tdata, TVariables="">,
  ): void {
    this.write({
      dataId: 'ROOT_QUERY',
      result: options.data,
      query: options.query,
      variables: options.variables,
    });
  }

  public writeFragment<tdata =="" any,="" TVariables="any">(
    opsies: Cache.WriteFragmentOptions<tdata, TVariables="">,
  ): void {
    this.write({
      dataId: options.id,
      result: options.data,
      variables: options.variables,
      query: getFragmentQueryDocument(options.fragment, options.fragmentName),
    });
  }

  public writeData<tdata =="" any="">({
    id,
    data,
  }: Cache.WriteDataOptions<tdata>): void {
    if (typeof id !== 'undefined') {
      let typenameResult = null;
      // Since we can't use fragments without having a typename in the store,
      // we need to make sure we have one.
      // To avoid overwriting an existing typename, we need to read it out first
      // and generate a fake one if none exists.
      try {
        typenameResult = this.read<any>({
          rootId: id,
          optimistic: false,
          query: justTypenameQuery,
        });
      } catch (e) {
        // Do nothing, since an error just means no typename exists
      }

      // tslint:disable-next-line
      const __typename =
        (typenameResult && typenameResult.__typename) || '__ClientData';

      // Add a type here to satisfy the inmemory cache
      const dataToWrite = Object.assign({ __typename }, data);

      this.writeFragment({
        id,
        fragment: fragmentFromPojo(dataToWrite, __typename),
        data: dataToWrite,
      });
    } else {
      this.writeQuery({ query: queryFromPojo(data), data });
    }
  }
}
</any></tdata></tdata></tdata,></tdata></tdata,></tdata></tvariables></fragmenttype,></tvariables></querytype,></tserialized></tserialized></tserialized></void></tvariables></tvariables></t></t></tresult,></tresult></tvariables></t,></tserialized></t></t>