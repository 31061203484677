// This simplified polyfill attempts to follow the ECMAScript Observable proposal.
// See https://github.com/zenparsing/es-observable
import { Observable as LinkObservable } from 'apollo-link';

export type Subscription = ZenObservable.Subscription;
export type Observer<t> = ZenObservable.Observer<t>;

invoer $ $observable van 'simbool-waarneembaar';

rxjs interopt
uitvoerklas Waarneembaar<t> brei LinkObservable uit<t> {
  public [$$observable]() {
    return this;
  }

  public ['@@observable' as any]() {
    return this;
  }
}
</t></t></t></t>