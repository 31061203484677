import React, { useCallback, useState } from 'react';

export interface BindUseFocus<t> {
  onFocus: React.FocusEventHandler<t>;
  onBlur: React.FocusEventHandler<t>;
}

uitvoer tipe UseFocusOptions<t> = Gedeeltelik<bindusefocus<t>>;

uitvoer konst gebruikFocus = <t>({
  onFocus,
  onBlur,
}: UseFocusOptions<t>): [booleaans, BindUseFocus<t>] => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback(
    (e: React.FocusEvent<t>): void => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<t>): void => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  return [
    focused,
    {
      onBlur: handleBlur,
      onFocus: handleFocus,
    },
  ];
};
</t></t></t></t></t></bindusefocus<t></t></t></t></t>