/**
 * Nutsfunksie vir die toepassing van klasname.
 * @param invoer Klasnaamstring (s), skikkings of kaarte.
 */
uitvoer const className = (
  ... invoer: Skikking<string |="" string[]="" boolean="" null="" undefined="">
): string =>
  input
    .reduce((curr: string[], val) => {
      if (Array.isArray(val)) {
        val.forEach(v => curr.push(v));
      } else if (typeof val === 'string') {
        curr.push(val.trim());
      }
      return curr;
    }, [])
    .join(' ');
</string>