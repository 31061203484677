import React from 'react';
import { Typography } from './typography';
import { TypographyProps } from './typography-props';
import { TypographyVariant } from '@mint/core';
import styled from 'styled-components';

const makeVariantComponent = (
  variant: TypographyVariant,
  displayName: string,
) => {
  const base = React.forwardRef<any, TypographyProps="">(
    (rekwisiete, ref): React.ReactElement => (
      <typography variant="{variant}" {...props}="" ref="{ref}"></typography>
    ),
  );
  base.displayName = displayName;
  terugkeer gestileer(basis)'';
};

uitvoer const BodyText = makeVariantComponent ('liggaam', 'BodyText');

uitvoer const ButtonText = makeVariantComponent ('knoppie', 'ButtonText');

uitvoer const CaptionText = makeVariantComponent ('caption', 'CaptionText');

uitvoer const ControlText = makeVariantComponent ('control', 'ControlText');

uitvoer konst DenseText = makeVariantComponent ('dig', 'DenseText');

uitvoer const HeadingOneText = makeVariantComponent ('h1', 'HeadingOneText');

uitvoer const HeadingTwoText = makeVariantComponent ('h2', 'HeadingTwoText');

uitvoer const HeadingThreeText = makeVariantComponent ('h3', 'HeadingThreeText');

uitvoer const HeadingFourText = makeVariantComponent ('h4', 'HeadingFourText');

uitvoer const HeadingFiveText = makeVariantComponent ('h5', 'HeadingFiveText');

uitvoer const HeadingSixText = makeVariantComponent ('h6', 'HeadingSixText');

uitvoer const HelperText = makeVariantComponent ('helper', 'HelperText');

uitvoer const OverlineText = makeVariantComponent ('overline', 'OverlineText');

uitvoer const SubtitleText = makeVariantComponent ('subtitel', 'SubtitleText');
</any,>