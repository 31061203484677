/**
 * Voeg die eienskappe van een of meer bronvoorwerpe by 'n teikenvoorwerp. Werk presies soos
 * 'Object.assign', maar as 'n hulpmiddel om ondersteuning vir IE 11 te behou.
 *
 * @see https://github.com/apollostack/apollo-client/pull/1009
 */
Uitvoer funksie toewys<a, B="">(a: A, b: B): A & B;
Uitvoer funksie toewys<a, B,="" C="">(a: A, b: B, c: C): A & B & C;
Uitvoer funksie toewys<a, B,="" C,="" D="">(a: A, b: B, c: C, d: D): A & B & C & D;
Uitvoer funksie toewys<a, B,="" C,="" D,="" E="">(
  a: A,
  b: B,
  c: C,
  d: D,
  e: E,
): A & B & C & D & E;
uitvoerfunksie toewys (teiken: enige, ... bronne: Skikking<any>): any;
export function assign(
  target: { [key: string]: any },
  ...sources: Array<{ [key: string]: any }>
): { [key: string]: any } {
  sources.forEach(source => {
    if (typeof source === 'undefined' || source === null) {
      return;
    }
    Object.keys(source).forEach(key => {
      target[key] = source[key];
    });
  });
  return target;
}
</any></a,></a,></a,></a,>